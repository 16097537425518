globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"b5be7dc190d7645aa37feda9e84d4cb29d5827e9"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
import { initSentry } from "./sentry.config"
import {
  browserTracingIntegration,
  browserProfilingIntegration,
} from "@sentry/nextjs"

initSentry({
  integrations: [browserTracingIntegration(), browserProfilingIntegration()],
  tracePropagationTargets: ["paragraph.xyz/api", "api.paragraph.xyz"],
})
